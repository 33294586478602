<template>
	<div class="createPatient">
		<div class="necessary">
			<div class="necessary_title">患者被诊断的疾病是什么？</div>
			<field title="疾病" type="3" poptype="1" ref="illType"></field>
		</div>
		<!-- <div v-if="carcuStageShow" class="selective ">
			<div class="selective_title">疾病分期</div>
			<field title="分期" type="3" poptype="5" ref="carcuStage"></field> -->
		<!-- </div> -->
		<div class="selective ">
			<div class="selective_title"></div>
			<field title="是否手术" type="2" ref="isOperate"></field>
			<field title="是否化疗" type="2" ref="isChemothe" :isEditText="true" areaTips="请填写化疗方案（选填）"></field>
			<field title="靶向治疗" type="2" ref="isTarget" :isEditText="true" areaTips="请填写靶向药物（选填）"></field>
			<field title="免疫治疗" type="2" ref="immuno" :isEditText="true" areaTips="请填写免疫药物（选填）"></field>
			<field title="放射治疗" type="2" ref="radio"></field>
			<field title="其他治疗方式" type="2" ref="otherTreatment" :isEditText="true" areaTips="请填写您的治疗方式（选填）"></field>
		</div>
		<div class="selective ">
			<div class="selective_title">患者的出生日期是？</div>
			<field title="出生日期" type="3" poptype="4" ref="birthday"></field>
		</div>
		<div class="selective ">
			<div class="selective_title">患者的性别是？</div>
			<field title="性别" type="2" first="男" second="女" ref="sex"></field>
		</div>
		<!-- <div class="levelBox">
			<div class="level_title">患者日常活动水平的最佳描述是：</div>
			<level ref="healthState"></level>
		</div> -->
		<div class="selective ">
			<div class="selective_title">患者的称呼：</div>
			<field title="患者称呼" type="1" placeholder="如:小王" ref="name"></field>
		</div>
		<div class="relation">
			<div class="relation_title">我和患者的关系：</div>
			<field title="关系" type="3" ref="relate" poptype="6"></field>
		</div>
		<div class="commitBtn" @click="commit">
			提交匹配
		</div>
	</div>
</template>

<script>
	import {
		Button
	} from 'vant';
	import field from '@/components/patient/field';
	import level from '@/components/patient/level';
	import patientApi from '@/api/patient/patientApi.js'
	export default {
		components: {
			Button,
			field,
			level
		},
		data() {
			return {
				id:this.$route.query.id,//患者id
				data: {
					name: '', //患者称呼
					sex: '', //性别1男 2女
					illType: '', //癌种类型
				},
				carcuStageShow: true
			}
		},
		methods: {
			//获取患者信息 赋值
			getDeail(){
				patientApi.get({id:this.id}).then(rs=>{
					const {
						illType,//疾病
						illId,//癌种id
						// carcuStage,//分期
						// carcuStageName,//分期的文字
						isOperate,//是否手术
						isChemothe,//是否化疗
						chemothe,//化疗方案
						isTarget,//是否靶向治疗
						targetMedicine, //靶向药物
						immuno,//是否免疫治疗
						immunoMedic,//免疫药物
						radio,//是否放射治疗
						isOtherTreatment,//是否其他治疗
						otherTreatment,//治疗方式
						birthday,//出生日期
						sex,//性别
						// healthState,//体力水平 key值
						name,//患者称呼
						relate,//我和患者的关系key
						relateName,//我和患者的关系value
					}=rs.data
					this.$refs.illType.setValue('illType',illType, illId);
					this.$refs.isOperate.setValue('isOperate',isOperate);
					// this.$refs.carcuStage.setValue('carcuStage',carcuStage,illId,carcuStageName);
					this.$refs.isChemothe.setValue('isChemothe',isChemothe,null,chemothe); 
					this.$refs.isTarget.setValue('isTarget',isTarget,null,targetMedicine); 
					this.$refs.immuno.setValue('immuno',immuno,null,immunoMedic); 
					this.$refs.radio.setValue('radio',radio,null,null); 
					this.$refs.otherTreatment.setValue('isOtherTreatment',isOtherTreatment,null,otherTreatment); 
					this.$refs.birthday.setValue('birthday',birthday); 
					this.$refs.sex.setValue('sex',sex); 
					// this.$refs.healthState.setVal(healthState);
					this.$refs.name.setValue('name',name); 
					this.$refs.relate.setValue('relate',relateName,relate); 
					patientApi.stagesList({illId}).then(rs=>{
						if(rs.data.length==0){
							this.carcuStageShow = false
						}else if(rs.data.length>0){
							this.carcuStageShow = true
						}
						
					})

				})
				
				
			},
			
			//提交更新患者信息
			commit() {
				let data = {
					id:this.id,
					illType: this.$refs.illType.tempName, //疾病
					illId:this.$refs.illType.tempId,//癌种id
					// carcuStage: this.carcuStageShow ? this.$refs.carcuStage.tempId : '', //分期
					isOperate: this.$refs.isOperate.getStatus(), //是否手术
					isChemothe: this.$refs.isChemothe.getStatus(), //是否化疗
					chemothe: this.$refs.isChemothe.tempName, //化疗方案
					isTarget: this.$refs.isTarget.getStatus(), //是否靶向治疗
					targetMedicine: this.$refs.isTarget.tempName, //靶向药物
					immuno: this.$refs.immuno.getStatus(), //是否免疫治疗
					immunoMedic: this.$refs.immuno.tempName, //免疫药物
					radio: this.$refs.radio.getStatus(), //是否放射治疗
					isOtherTreatment:this.$refs.otherTreatment.getStatus(),//是否其他治疗
					otherTreatment:this.$refs.otherTreatment.tempName,//治疗方式
					birthday: this.$refs.birthday.tempName, //生日
					sex: this.$refs.sex.getStatus(), //性别
					// healthState: this.$refs.healthState.currentKey, //体力水平
					name: this.$refs.name.tempName, //患者称呼
					relate:this.$refs.relate.tempId,//缺少我和患者的关系
				}
				console.log(data)
				// return;
				patientApi.save(data).then(rs=>{
					if(rs.status==1){
						this.$router.go(-1)
						// this.$router.push({
						// 	path:'',
						// 	query: { 
								
						// 	} 
						// })
					}
				})
			},
		},
		mounted() {
			this.getDeail();
		}
	}
</script>

<style lang="less" scoped="scoped">
	.createPatient {
		background: rgba(247, 247, 247, 1);
		height: auto;

		.necessary {
			.necessary_title {
				width: 100%;
				height: 44px;
				background: rgba(247, 247, 247, 1);
				font-size: 14px;
				font-weight: 400;
				color: #828284;
				text-align: left;
				line-height: 44px;
				padding-left: 15px;
				box-sizing: border-box;
				// font-weight: bold;
			}

		}

		.selective {
			.selective_title {
				width: 100%;
				height: 44px;
				background: rgba(247, 247, 247, 1);
				font-size: 14px;
				font-weight: 400;
				color: #828284;
				text-align: left;
				line-height: 44px;
				padding-left: 15px;
				box-sizing: border-box;
				// font-weight: bold;
			}
		}

		.levelBox {
			.level_title {
				width: 100%;
				height: 44px;
				background: rgba(247, 247, 247, 1);
				font-size: 14px;
				font-weight: 400;
				color: #828284;
				text-align: left;
				line-height: 44px;
				padding-left: 15px;
				box-sizing: border-box;
				// font-weight: bold;
			}
		}

		.relation {
			.relation_title {
				width: 100%;
				height: 44px;
				background: rgba(247, 247, 247, 1);
				font-size: 14px;
				font-weight: 400;
				color: #828284;
				text-align: left;
				line-height: 44px;
				padding-left: 15px;
				box-sizing: border-box;
				// font-weight: bold;
			}
		}

		.commitBtn {
			width: 100%;
			height: 49px;
			background: rgba(0, 96, 194, 1);
			text-align: center;
			line-height: 49px;
			font-size: 17px;
			font-weight: 600;
			color: rgba(255, 255, 255, 1);
			margin-top: 50px;
		}
	}
</style>
